.player-wrapper {
	position: relative;
	padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
	position: absolute;
	top: 0;
	left: 0;
  }

  .contact-card {
	height: 113px;
	background: rgb(46, 56, 68);
	border-top-left-radius: 0.428rem;
	border-top-right-radius: 0.428rem;
  }

  .sh-100 {
	min-height: 120px!important;
  }

  .top-bar-item {
	margin-right: 0.428rem;
  }

  .license-tags .rdt_TableCell {
	height: 34px!important;
  }