// ================================================================================================
//     File Name: content.scss
//     Description: Page content level SCSS for different screen size, layout and device.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Authuseror URL: http://www.themeforest.net//pixinvent
// ================================================================================================

// Contest margin left for expanded & collapsed stats width & margin left
// ================================================================================================

@import '../mixins/main-menu-mixin';

html {
    font-size: $base-font-size;
    height: 100%;
    letter-spacing: 0.01rem;

    body {
        height: 100%;
        background-color: $body-bg;
        direction: $body-direction;
        // paragraph line-height
        p {
            line-height: 1.5rem;
        }

        h1, h2, h3, h4, h5, h6 {
            font-family: $font-family-headers !important;
            font-weight: 400;
            color: #2E3844;
        }
    }

    .content {
        padding: 0;
        position: relative;
        transition: 300ms ease all;
        backface-visibility: hidden;
        min-height: calc(100% - #{$footer-height});
        margin-left: $menu-expanded-width;

        &.app-content {
            padding: calc(#{$content-padding} + #{$navbar-height} + #{$floating-nav-margin}) #{$content-padding} 0;

            &.show-overlay {
                .content-overlay {
                    z-index: 10;
                    opacity: 1;

                    ~ .header-navbar-shadow {
                        display: none;
                    }
                }
            }

            .content-overlay {
                position: fixed;
                opacity: 0;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($black, 0.5);
                cursor: pointer;
                transition: all 0.7s, z-index 0s;
                z-index: -1;
            }

            .content-area-wrapper {
                display: flex;
                position: relative;
                overflow: hidden; // Required in apps
                .content-wrapper,
                .content-body {
                    height: 100%;
                }
            }
        }

        .body-content-overlay {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            display: block;
            z-index: 4;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease;

            &.show {
                visibility: visible;
                transition: all 0.3s ease;
                opacity: 1;
                background-color: rgba($black, 0.2);
                border-radius: $border-radius/2;
            }
        }

        .content-wrapper {
            .content-header-title {
                font-weight: 500;
                color: $gray-400;
                padding-right: $spacer;
                border-right: 1px solid lighten($gray-600, 10%);
            }

            .content-header-right {
                .dropdown-toggle {
                    &::after {
                        display: none;
                    }

                    i,
                    svg {
                        margin-right: 0;
                    }
                }

                .btn-icon {
                    padding: 0.8rem;

                    i,
                    svg {
                        height: $font-size-base;
                        width: $font-size-base;
                    }
                }
            }
        }
    }
    // kanban app uses kanban-wrapper class as not having content-layout so added this class with all the options
    // For floating navbar
    .navbar-floating {
        &.footer-static {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height( #{$content-padding}, #{$navbar-height}, #{$footer-height}, #{$floating-nav-margin} );
                }
            }
        }

        &.footer-hidden {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height( #{$content-padding}, #{$navbar-height}, 0rem, #{$floating-nav-margin}, 0rem, 2 );
                }
            }
        }

        &.footer-fixed {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height( #{$content-padding}, #{$navbar-height}, #{$footer-height}, #{$floating-nav-margin}, 0rem, 2 );
                }
            }
        }
    }
    //for navbar hidden
    .navbar-hidden {
        .app-content {
            padding: #{$content-padding} #{$content-padding} 0 #{$content-padding} !important;
        }

        &.footer-static {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height(#{$content-padding}, 0rem, #{$footer-height});
                }
            }
        }

        &.footer-hidden {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height(#{$content-padding}, 0rem, 0rem, 0rem, 0rem, 2);
                }
            }
        }

        &.footer-fixed {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height(#{$content-padding}, 0rem, #{$footer-height}, 0rem, 0rem, 2);
                }
            }
        }
    }
    // for static navbar
    .navbar-static {
        .main-menu {
            top: 0;
        }

        .content {
            min-height: calc(100% - calc(#{$navbar-height} + #{$footer-height}));
        }

        .app-content {
            padding: #{$content-padding} #{$content-padding} 0 #{$content-padding};
        }

        &.footer-static {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height(#{$content-padding}, #{$navbar-height}, #{$footer-height});
                }
            }
        }

        &.footer-hidden {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height(#{$content-padding}, #{$navbar-height}, 0rem, 0rem, 0rem, 2);
                }
            }
        }

        &.footer-fixed {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height( #{$content-padding}, #{$navbar-height}, #{$footer-height}, 0rem, 0rem, 2 );
                }
            }
        }
    }
    // for sticky navbar
    .navbar-sticky {
        .app-content {
            padding: calc(#{$content-padding} + #{$navbar-height}) #{$content-padding} 0 #{$content-padding};
        }

        &.footer-static {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height(#{$content-padding}, #{$navbar-height}, #{$footer-height});
                }
            }
        }

        &.footer-hidden {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height(#{$content-padding}, #{$navbar-height}, 0rem, 0rem, 0rem, 2);
                }
            }
        }

        &.footer-fixed {
            .app-content {
                .content-area-wrapper,
                .kanban-wrapper {
                    @include content-area-wrapper-height( #{$content-padding}, #{$navbar-height}, #{$footer-height}, 0rem, 0rem, 2 );
                }
            }
        }
    }
    // navbar search width for sticky and static navbar
    .navbar-static,
    .navbar-sticky {
        .nav.header-navbar {
            .navbar-container {
                padding-left: 1.6rem;
                padding-right: 1.6rem;

                .search-input {
                    .search-list {
                        &.show {
                            width: 98%;
                            left: 1%;
                        }
                    }
                }
            }
        }
    }
    // Fixed layout
    .footer-fixed {
        .content {
            &.app-content {
                padding-bottom: $footer-height !important; // ? Added extra padding bottom due to fixed footer height
            }
        }
    }
    //Remove left margin for 1 column layout
    [data-col='1-column'] {
        .header-navbar {
            // Custom width for floating nav
            &.floating-nav {
                width: calc( 100vw - (100vw - 100%) - calc(#{$content-padding} * 2) ) !important; // ? override all navbar type width
            }

            width: 100%; // Full width for other nav options
            left: 0 !important;
        }

        .content,
        .footer {
            margin-left: 0px !important;
        }
        // horizontal layout
        &.horizontal-layout {
            .header-navbar {
                width: 100% !important;
            }

            .app-content {
                padding: calc(#{$content-padding} + #{$navbar-height} + #{$floating-nav-margin}) #{$content-padding} 0 #{$content-padding} !important; // ? need to check all navbar types case
            }

            &.footer-fixed {
                .app-content {
                    padding: calc(#{$content-padding} + #{$navbar-height} + #{$floating-nav-margin}) #{$content-padding} #{$footer-height} #{$content-padding} !important; // ? need to check all navbar types case
                }
            }
        }
    }
    /*
    * Blank page
    */

    .blank-page {
        .content {
            margin-left: 0; // not using in both cases
            // overflow-x hidden, overflow for y in sm screen issue resolved default
            &.app-content {
                overflow: overlay;
                overflow-x: hidden;
                padding: 0 !important;
                transition: none;
                // remove white space on top by header-navbar-shadow class
                .header-navbar-shadow {
                    display: none;
                }
            }
        }
        // blank page content-wrapper
        .content-wrapper {
            padding: 0 !important;
            // content to be in center horizontally and vertically
            .flexbox-container {
                display: flex;
                align-items: center;
                height: 100vh;
                height: calc(var(--vh, 1vh) * 100);
                justify-content: center;
            }
        }
    }

    .pace {
        .pace-progress {
            background: $primary;
        }
    }
}

.app-content.center-layout {
  overflow: hidden;
}

/*
* Col 3 layout for detached and general type
*/

@include media-breakpoint-up(lg) {
  body {
    // Normal sidebar
    .content-right {
      width: calc(100vw - (100vw - 100%) - #{$sidebar-width});
      float: right;
    }
    .content-left {
      width: calc(100vw - (100vw - 100%) - #{$sidebar-width});
      float: left;
    }
    // Detached sidebar
    .content-detached {
      width: 100%;
      &.content-right {
        float: right;
        margin-left: -$sidebar-width;
        .content-body {
          margin-left: calc(#{$sidebar-width} + #{$content-padding});
        }
      }
      &.content-left {
        float: left;
        margin-right: -$sidebar-width;
        .content-body {
          margin-right: calc(#{$sidebar-width} + #{$content-padding});
        }
      }
    }
  }
  .sidebar-right.sidebar-sticky {
    float: right !important;
    margin-left: -$sidebar-width;
    width: $sidebar-width !important;
    margin-top: $navbar-height + 1.25;
  }
}

@include media-breakpoint-down(md) {
  html body .app-content {
    padding: calc(#{$content-padding} - 0.8rem + #{$navbar-height} + #{$floating-nav-margin})
      calc(#{$content-padding} - 0.8rem) 0 calc(#{$content-padding} - 0.8rem) !important; // - 0.8rem to reduce padding in small screen
  }

  // For static navbar
  html body.navbar-static .app-content {
    padding: calc(#{$content-padding} - 0.8rem) calc(#{$content-padding} - 0.8rem) 0 calc(#{$content-padding} - 0.8rem) !important; // - 0.8rem to reduce padding in small screen
  }

  // For sticky navbar
  html body.navbar-sticky .app-content {
    padding: calc(#{$content-padding} - 0.8rem + #{$navbar-height}) calc(#{$content-padding} - 0.8rem) 0
      calc(#{$content-padding} - 0.8rem) !important; // - 0.8rem to reduce padding in small screen
  }

  // For Hidden navbar
  html body.navbar-hidden .app-content {
    padding: calc(#{$content-padding} - 0.8rem) calc(#{$content-padding} - 0.8rem) 0 calc(#{$content-padding} - 0.8rem) !important; // - 0.8rem to reduce padding in small screen
  }
}

@include media-breakpoint-down(md) {
  .navbar-static,
  .navbar-sticky {
    .app-content {
      .header-navbar {
        .navbar-container {
          padding-left: 1rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
}

// For Fullscreen IE
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html.full-screen {
    width: 100%;
    .content.app-content {
      height: 100%;
      overflow: scroll;
      overflow-x: hidden;
    }
  }
}
